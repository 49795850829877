import React, { useState, useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { ChevronRight } from "react-feather";

import Button from "../components/button";
import { Star } from "lucide-react";

export const TestimonialVideo = ({
  id,
  title,
  showTitle,
  thumbnail,
  subtitle,
  video,
  columnsClassName,
  AfterTitleComponent,
  AfterImageComponent,
  slug,
  projectReference,
  rounded,
  projectsPage,
  responsive,
}) => {
  const videoElement = useRef();
  const [isOpen, updateIsOpen] = useState(false);
  const playVideo = () => {
    updateIsOpen(true);
    try {
      videoElement.current.play();
    } catch (e) {
      console.log("error playing video", "browser may not support media API");
    }
  };
  return (
    <div> 
    <div key={id} className="rounded-xl overflow-hidden shadow-md border border-gray-200 bg-white">
      {AfterTitleComponent && <AfterTitleComponent />}
      <div>
        <div className={`relative videoWrapper h-full w-full bg-white`} key={id}>
          <span
            className={" absolute w-full h-full play-overlay m-auto " + (!isOpen ? "block" : "hidden")}
            style={{
              zIndex: 10,
              cursor: "pointer",
              top: "-100%",
              bottom: "-100%",
            }}
            onClick={playVideo}
          >
            <span className="play-button-overlay hidden md:block">
              <img src="/assets/play.svg" onClick={playVideo} alt="Play Video" loading="lazy" />
            </span>
          </span>
          <GatsbyImage
            image={getImage(thumbnail)}
            className={`${rounded ? "border-radius" : "border-radius-top"} mb-0`}
            onClick={playVideo}
            alt={`${video.title} Video Testimonial`}
          />
          {isOpen && (
            <video
              ref={videoElement}
              controls={true}
              name="media"
              preloaded="none"
              className={`${rounded ? "border-radius" : "border-radius-top"} w-full ${isOpen ? "block" : "hidden"} `}
              poster={thumbnail.gatsbyImageData.images.fallback.src}
              autoPlay={true}
            >
              <source src={video.file.url} type={video.file.contentType} />
              Your browser does not support the video tag.
            </video>
          )}
          
        </div>
        {AfterImageComponent && <AfterImageComponent />}
         
      </div>
      
    </div>{showTitle && (
      <div className="  flex justify-between items-center">
         <div className="font-display">
        <h4 className="text-left block mt-4 leading-5 font-semibold">{title}</h4>
        <p className="text-neutral-600 text-left">{subtitle ? subtitle : ''}</p>
        </div>
         <div className={`flex items-center    justify-`}>
          {new Array(5).fill("").map((item) => (
            <Star fill={"#f28d24"} stroke={"0"} size={16} />
          ))}
        </div> 
       
        </div>
      )}

    </div>
  );
};

export const TestimonialSection = ({ testimonial_video_posts, filters }) =>
  testimonial_video_posts.length > 0 && (
    <>
      <section className="max-w-7xl mx-auto px-4 md:px-6 py-20">
        <div>
        <p className="uppercase text-brand-600 font-semibold tracking-wide text-sm -mb-4">Customers love us</p>
          <h2 className=" text-3xl md:text-4xl font-display text-nuetral-900 font-bold mb-6">
          Watch What Some of Our Clients Have to Say
          </h2>

       
          <div
            className={`grid   gap-8 py-4 pt-4 lg:pt-1 pb-0 lg:pb-4 ${
              testimonial_video_posts.length > 3 ? "md:grid-cols-4" : "md:grid-cols-3"
            }`}
          >
            {testimonial_video_posts.map((props, i) => (
              <TestimonialVideo showTitle key={i} {...props} />
            ))}
          </div>
          
        </div>
      </section>
    </>
  );
