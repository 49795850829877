import React from "react";
import TestimonialsAvatars from "./TestimonialsAvatars";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArrowRight } from "lucide-react";
import { motion } from "framer-motion";
import Button from "./atoms/Button";
import ReviewSitesInlineRatings from "./ReviewSitesInlineRatings";
import ContactForm from "./ContactForm"; 
const ServiceHeroFullBGService = ({
  heroImage,
  serviceTitle,
  excerpt, 
  specialOffer
}) => {
 
 const heroTitle = serviceTitle;


  const wrapTextWithSpan = (inputStr) => {
    const regex = /\*(.*?)\*/g;
    const replacedStr = inputStr.replace(regex, '<span class="text-brand-600">$1</span>');
    return replacedStr;
  };

  return (
    <div className="relative  shadow-sm  overflow-hidden  ">
       {/* <div
        className="absolute top-0 left-0 w-full h-full z-[4] bg-[linear-gradient(125deg,rgba(249,253,255,.63)_60%,transparent_60%)]"/>
      <div
        className="absolute top-0 left-0 w-full h-full z-[4] bg-[linear-gradient(149deg,rgba(255,255,255,.75)_40%,transparent_40%)]
        md:bg-[linear-gradient(59deg,rgba(255,255,255,.7)_55%,transparent_55%)]"/> */}



<div
        className="absolute top-0 left-0 w-full h-full z-[4] bg-[linear-gradient(170deg,rgba(10,10,10,.75),transparent)] md:bg-[linear-gradient(45deg,rgba(10,10,10),transparent)]"/>
 
   
   
         <GatsbyImage
        image={getImage(heroImage)}
        alt={serviceTitle}
    
        className="absolute top-0 left-0 w-full max-w-none h-full absolute-important h-full z-1 object-cover"
      />

      {/* <Image src="/images/lp-siding.jpeg" alt="me" width="500" height="500"  className="absolute top-0 left-0 w-full h-full z-0 object-cover" style={{ clipPath: 'polygon(60% 0, 100% 0%, 100% 100%, 50% 100%)'}}/> */}

      <section className="z-[10] relative max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-center gap-16 lg:gap-20  px-4 md:px-6 py-4 py-8 lg:py-16">
        <div className="lg:w-3/5 flex-shrink-0 flex flex-col gap-4 lg:gap-8 items-center justify-center text-center lg:text-left lg:items-start lg:pr-12">
          {/* <ReviewSitesInlineRatings /> */}
          {/* <div>
            <ReviewSitesInlineRatings invert/> 
            </div> */}
          <motion.div
            initial={{ opacity: 0, y: 7 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.35 } }}
            staggerChildren={0.8}
            viewport={{ once: true }}
          >
           <p className="border-l-4 border-brand-600 pl-3 uppercase text-brand-500 font-semibold tracking-wide text-sm mb-4">Top Tier Roofing</p>
           <h1
              className="font-extrabold text-white text-3xl lg:text-5xl tracking-tight mb-4 text-balance"
              dangerouslySetInnerHTML={{ __html: wrapTextWithSpan(heroTitle) }}
            />

            {excerpt && (
              <p
                className="text-xl font-display text-gray-200 font-light mb-6 md:pr-8"
                dangerouslySetInnerHTML={{ __html: excerpt  }}
              />
            )}
              
            <div className="my-10">
              <Button color="brandGradient" size="xl" className="w-full md:w-auto"to="/contact/">
                Get a FREE Estimate <ArrowRight size={18} />
              </Button>
            </div>
        <TestimonialsAvatars priority={true}  inline  invert/> 
          </motion.div>
        </div>
        <div className="lg:w-full relative">
          {/* <Image src="/images/lp-siding.jpeg" alt="me" width="500" height="500"  className="z-1 absolute top-0 right-0 w-full h-full z-0 object-cover" style={{ clipPath: 'polygon(30% 0, 100% 0%, 100% 100%, 0% 100%)'}}/> */}

          {/* <div className="absolute -left-12 bottom-0 -mb-12 w-8 overflow-hidden"><div className="flex -ml-px h-80 w-[2px]"><div className="w-full flex-none blur-sm [background-image:linear-gradient(to_top,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div><div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(to_top,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div></div></div> */}

          <div className="shadow-2xl rounded-xl p-8 bg-white relative z-1 min-w-[400px] relative ">
            <div
              style={{ transform: "rotate(10deg)", top: "-12px" }}
              class="special-tag rounded-r-none absolute bg-orange-500 font-display font-semibold inline-flex items-center mb-2 px-3 py-2 right-0 rounded-lg uppercase text-sm text-white"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="text-white w-5 h-5 mr-1">
                <path
                  fill="currentColor"
                  d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"
                />
                <path
                  fill="currentColor"
                  className="opacity-50"
                  d="M311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93V38.93z"
                />
              </svg>
              <span className="font-display text-xs">Online Special</span>
            </div>
            <h2 className="font-bold font-display text-xl mb-1">{specialOffer ? specialOffer : "Request " + serviceTitle + " Consultation"}</h2>
             <div className="py-2 border-t border-neutral-100 pt-4 mt-4">
              {" "}
              <ReviewSitesInlineRatings />
              {/* <TestimonialsAvatars priority={true} inline className="text-white font-display text-sm" /> */}
            </div>
                       

            <div className="border-t border-neutral-100 pt-4">
              <ContactForm showPlaceholders={true}  hideLabels={true} stacked={false}/>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceHeroFullBGService;
